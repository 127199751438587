








































































































































































import { Component, Vue } from 'vue-property-decorator'
import { Validator, ValidationProvider, ValidationObserver } from 'vee-validate'
import LayoutSideBar from '@/layouts/LayoutSideBar.vue'
import ChangePasswordService from '@/services/ChangePasswordService'

const dict = {
  custom: {
    currentPassword: {
      required: () => '「現在のパスワード」を入力してください。'
    },
    password: {
      required: () => '「新しいパスワード」を入力してください。',
      min: () => '「パスワード」を大文字を含む半角英数字9文字以上20文字以下で入力してください。',
      regex: () => 'パスワードに無効な文字が含まれています。'
    },
    confirmPassword: {
      required: () => '「新しいパスワード」を入力してください。',
      confirmed: () => '「現在のパスワード」と「新しいパスワード」が異なっているようです。ご確認ください。'
    }
  }
}
Validator.localize('jp', dict)

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    LayoutSideBar
  }
})
export default class ChangePassword extends Vue {
  public pattern: string = `^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9|@#$!%*?,./])(?!.*[¥&<>"'])[a-zA-Z\\d@#$!%*?,./]+$`
  public message: boolean = false
  public messagePassOld: boolean = false
  public messagePassHasBeenUsed: boolean = false
  public currentPasswordFail: boolean = false

  private password: any = {
    current_password: '',
    password: '',
    password_confirmation: ''
  }

  public changePassword() {
    ChangePasswordService.changePassword(this.password).then(
      (response: { data: { status: any } }) => {
        if (response.data.status) {
          this.$router.push({
            name: 'change-password-result',
            query: { status: 'true' }
          })
        }
      },
      (error: any) => {
        if (error) {
          if (error.response.status === 429) {
            localStorage.clear()
            this.$bvModal.show(`too-many-request`)
          }
          if (error.response.status === 440) {
            if (
              error.response.data.message[0] ==
              'PASSWORD_AND_EMAIL_CAN_NOT_SAME'
            ) {
              this.message = true
            } else if (
              error.response.data.message[0] ==
              'CANNOT_USE_PREVIOUS_PASSWORD'
            ) {
              this.messagePassHasBeenUsed = true
            }else if (
              error.response.data.message[0] ==
              'CURRENT_PASSWORD_NOT_MATCH'
            ) {
              this.currentPasswordFail = true
            } else {
              this.$router.push({
                name: 'change-password-result',
                query: { status: 'false' }
              })
            }
          } else {
            this.$router.push({
              name: 'change-password-result',
              query: { status: 'false' }
            })
          }
        }
      }
    )
  }
}
