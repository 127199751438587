var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutSideBar',[_c('div',{staticClass:"change-password pt-4"},[_c('div',{staticClass:"container"},[_c('h6',{staticClass:"title-section"},[_vm._v("パスワードの変更")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();validate().then(_vm.changePassword)}}},[_c('div',{staticClass:"fs-16 fs-xl-18 mx-auto mw-xl-620"},[_c('div',{staticClass:"mb-10"},[_c('label',{staticClass:"text-dark font-weight-bold mb-1",attrs:{"for":"currentPassword"}},[_vm._v(" 現在のパスワード"),_c('span',{staticClass:"text-danger-extra font-weight-normal fs-12 pl-3 fs-xl-18"},[_vm._v("必須")])]),_c('div',{staticClass:"position-relative"},[_c('ValidationProvider',{attrs:{"name":"currentPassword","rules":{
                    required: true
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var reset = ref.reset;
return [(errors[0])?_c('div',{staticClass:"alert-tooltip-block",on:{"click":reset}},[_c('div',{staticClass:"alert alert-tooltip alert-black text-light fs-14"},[_vm._v(" "+_vm._s(errors[0])+" ")])]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password.current_password),expression:"password.current_password"}],staticClass:"form-control form-control-xl-h-40 rounded-1",attrs:{"type":"password","id":"currentPassword"},domProps:{"value":(_vm.password.current_password)},on:{"click":reset,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.password, "current_password", $event.target.value)}}})]}}],null,true)})],1)]),_c('div',{staticClass:"pt-5 mb-8"},[_c('label',{staticClass:"text-dark font-weight-bold mb-1",attrs:{"for":"newPassword"}},[_vm._v(" 新しいパスワード "),_c('span',{staticClass:"text-danger-extra font-weight-normal fs-12 pl-3  fs-xl-18"},[_vm._v("必須")]),_c('p',{staticClass:"font-weight-normal fs-10 mb-1 fs-xl-18"},[_vm._v(" ※アルファベットの大文字・小文字を必ず1文字以上使用し、かつ数字か記号 を組み合わせて9文字以上で入力してください"),_c('br'),_vm._v(" ※全角英数字、半角記号「¥、&、<、>、”、’、（半角スペース）」は 使用できません"),_c('br'),_vm._v(" ※メールアドレスと同じものは使用できません ")])]),_c('div',{staticClass:"position-relative"},[_c('ValidationProvider',{attrs:{"vid":"checkPassword","name":"password","rules":{
                    required: true,
                    min: 9,
                    regex: ("" + _vm.pattern)
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var reset = ref.reset;
return [(errors[0])?_c('div',{staticClass:"alert-tooltip-block",on:{"click":reset}},[_c('div',{staticClass:"alert alert-tooltip alert-black text-light fs-14 mb-0"},[_vm._v(" "+_vm._s(errors[0])+" ")])]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password.password),expression:"password.password"}],staticClass:"form-control form-control-xl-h-40 rounded-1",attrs:{"id":"newPassword","type":"password"},domProps:{"value":(_vm.password.password)},on:{"click":reset,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.password, "password", $event.target.value)}}})]}}],null,true)})],1)]),_c('div',{staticClass:"mb-10"},[_c('label',{staticClass:"text-dark font-weight-bold mb-1",attrs:{"for":"confirmPassword"}},[_vm._v("新しいパスワード（確認） "),_c('span',{staticClass:"text-danger-extra font-weight-normal fs-12 pl-3 fs-xl-18"},[_vm._v("必須")])]),_c('div',{staticClass:"position-relative"},[_c('ValidationProvider',{attrs:{"name":"confirmPassword","rules":"required|confirmed:checkPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var reset = ref.reset;
return [(errors[0])?_c('div',{staticClass:"alert-tooltip-block",on:{"click":reset}},[_c('div',{staticClass:"alert alert-tooltip alert-black text-light fs-14 mb-0"},[_vm._v(" "+_vm._s(errors[0])+" ")])]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password.password_confirmation),expression:"password.password_confirmation"}],staticClass:"form-control form-control-xl-h-40 rounded-1",attrs:{"id":"confirmPassword","type":"password"},domProps:{"value":(_vm.password.password_confirmation)},on:{"click":reset,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.password, "password_confirmation", $event.target.value)}}})]}}],null,true)})],1)])]),(_vm.message)?_c('p',{staticClass:"text text-danger text-center fs-14 fs-xl-18"},[_vm._v(" メールアドレスとパスワードを同じにすることはできません。 ")]):_vm._e(),(_vm.messagePassHasBeenUsed)?_c('p',{staticClass:"text text-danger text-center fs-14 fs-xl-18"},[_vm._v(" 以前のパスワードと同じようです。「新しいパスワード」を入力してください。 ")]):_vm._e(),(_vm.currentPasswordFail)?_c('p',{staticClass:"text text-danger text-center fs-14 fs-xl-18"},[_vm._v(" 「パスワード」を大文字を含む半角英数字9文字以上20文字以下で入力してください。 ")]):_vm._e(),_c('div',{staticClass:"text-center mb-10 p-4"},[_c('button',{staticClass:"btn btn-primary btn-lg btn-block mx-auto mw-270 mh-30"},[_vm._v(" パスワードを変更する ")])])])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }