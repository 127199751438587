import axiosApiInstance from '@/interceptors'
import Const from '../constants'

class ChangePasswordService {
  changePassword(password: any) {
    return axiosApiInstance.post(Const.api_url + 'member/change-password', password)
  }
}

export default new ChangePasswordService()
